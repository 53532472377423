import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { primaryColor } from "../hooks/theme/primaryColor";

const SearchComponent = ({ search, setSearch, setPage }) => {
    const setSearching = (event) => {
        setSearch(event.target.value);
        setPage(1);
    };

    return (
        <TextField
            type="search"
            id="outlined-search"
            label="Search"
            size={"small"}
            sx={{ width: "200px", minWidth: "200px" }}
            InputProps={{
                endAdornment: search?.length === 0 && (
                    <InputAdornment sx={{ cursor: "pointer" }} position="end">
                        <SearchRoundedIcon style={{ color: primaryColor }} />
                    </InputAdornment>
                ),
            }}
            value={search}
            onChange={setSearching}
        />
    );
};
export default SearchComponent;
