import Reports from "../../pages/Reports";

const REPORT_ROUTES = [
    {
        name: "Top products",
        path: "/reports/top-products",
        component: () => <Reports page={"top-products"} />,
    },
    {
        name: "Transaction history",
        path: "/reports/transaction-history",
        component: () => <Reports page={"transaction-history"} />,
    },
    {
        name: "Sales report",
        path: "/reports/sales",
        component: () => <Reports page={"sales"} />,
    },
    {
        name: "Employee report",
        path: "/reports/employees",
        component: () => <Reports page={"employees"} />,
    },
    {
        name: "Log report",
        path: "/reports/logs",
        component: () => <Reports page={"logs"} />,
    },
];

export default REPORT_ROUTES;
