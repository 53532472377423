import axios from "../axios";

export const login = async (data) => {
  try {
    const response = await axios.post(`v1/login`, data);
    return response?.data;
  } catch (error) {
    console.log(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const logout = async (cookies, userCookie) => {
  try {
    const response = await axios.post(`v1/logout`, null, {
      headers: {
        Authorization: `Bearer ${cookies.user.token}`,
      },
    });
    userCookie.remove("user");
    return response?.data;
  } catch (error) {
    console.log(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};
