import Paper from "@mui/material/Paper";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { TablePaginationActions } from "../TablePagination";
import { useCookies } from "react-cookie";
import ChartJS from "chart.js/auto";
import {
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";
import dayjs from "dayjs";
import { useQueryClient } from "@tanstack/react-query";
import { useSalesReport } from "../../hooks/react-query/Sales";
import "dayjs/locale/en";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const Sales = () => {
    const queryClient = useQueryClient();
    const [cookies] = useCookies(["user"]);
    const [sortBy, setSortBy] = useState("yearly");
    const [year, setYear] = useState(dayjs());
    const [month, setMonth] = useState(dayjs());
    const [day, setDay] = useState(dayjs());
    const { data: sales } = useSalesReport(cookies, {
        sortBy,
        year,
        month,
        day,
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
        },
    };

    const labels = sales?.map((sale) =>
        sortBy === "daily"
            ? dayjs(sale.key, "H:mm:ss").format("h:mm A")
            : sale.key
    );

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Sales",
                data: sales?.map((sale) => sale.value),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };

    const handleChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleYearChange = (value) => {
        setYear(value);
        setMonth(dayjs(`${value.year()}-${month.month() + 1}-${day.date()}`));
        setDay(dayjs(`${value.year()}-${month.month() + 1}-${day.date()}`));
    };

    const handleMonthChange = (value) => {
        setMonth(value);
        setDay(dayjs(`${year.year()}-${value.month() + 1}-${dayjs().date()}`));
    };

    const handleDayChange = (value) => {
        setDay(value);
    };

    return (
        <Paper>
            <Typography
                component={"h5"}
                variant={"h5"}
                sx={{ fontWeight: "bold", padding: "15px" }}
            >
                SALES REPORT
            </Typography>
            <Box sx={{ px: "15px" }}>
                <FormControl sx={{ mr: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Age</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sortBy}
                        label="Sort by"
                        onChange={handleChange}
                    >
                        <MenuItem value={"yearly"}>Yearly</MenuItem>
                        <MenuItem value={"monthly"}>Monthly</MenuItem>
                        <MenuItem value={"daily"}>Daily</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ mr: "10px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={year}
                            onChange={handleYearChange}
                            label={"Year"}
                            views={["year"]}
                        />
                    </LocalizationProvider>
                </FormControl>
                {(sortBy === "monthly" || sortBy === "daily") && (
                    <FormControl sx={{ mr: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={month}
                                onChange={handleMonthChange}
                                label={"Monthly"}
                                views={["month"]}
                            />
                        </LocalizationProvider>
                    </FormControl>
                )}
                {sortBy === "daily" && (
                    <FormControl sx={{ mr: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={day}
                                onChange={handleDayChange}
                                label={"Daily"}
                                views={["day"]}
                            />
                        </LocalizationProvider>
                    </FormControl>
                )}
            </Box>
            <Box sx={{ my: 5 }}>
                <Bar options={options} data={data} />
            </Box>
        </Paper>
    );
};

export default Sales;
