import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import SearchComponent from "../../SearchComponent";

const HeaderTabOption = ({
    setPage,
    search,
    setSearch,
    handleOpenAdd,
    toggleScreen,
    screen,
}) => {
    return (
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button
                sx={{ mx: 1 }}
                variant="contained"
                startIcon={<PersonAddAlt1RoundedIcon />}
                onClick={() => handleOpenAdd()}
            >
                Add employee
            </Button>
            {screen ? (
                <Button
                    sx={{ mx: 1 }}
                    variant="outlined"
                    startIcon={<PeopleAltRoundedIcon />}
                    onClick={() => toggleScreen()}
                >
                    Manage employee
                </Button>
            ) : (
                <Button
                    sx={{ mx: 1 }}
                    variant="contained"
                    startIcon={<AdminPanelSettingsRoundedIcon />}
                    onClick={() => toggleScreen()}
                >
                    Manage permission
                </Button>
            )}
            <SearchComponent
                search={search}
                setSearch={setSearch}
                setPage={setPage}
            />
        </Box>
    );
};

export default HeaderTabOption;
