import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TablePaginationActions } from "../TablePagination";
import { useState } from "react";
import { useTransactionHistoryHeader } from "../../hooks/react-query/TableHeaders";
import { useCookies } from "react-cookie";
import { useMutation } from "@tanstack/react-query";
import { getTransactionHistory } from "../../api/transactionHistory";
import TableData from "./TableData";
import { useTransactionHistory } from "../../hooks/react-query/TransactionHistory";
import TransactionHeader from "./Header/index";
import ReturnModal from "./ReturnModal";

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TransactionHistory = () => {
    const [cookies] = useCookies(["user"]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: response } = useTransactionHistory(
        cookies,
        page,
        rowsPerPage
    );
    const [returnButton, setReturnButton] = useState(false);
    const { data: columns } = useTransactionHistoryHeader(returnButton);
    const [openReturn, setOpenReturn] = useState(false);
    const [returnItem, setReturnItem] = useState([]);

    const changePage = useMutation(
        ({ page }) => getTransactionHistory(cookies, page, rowsPerPage),
        {
            onSuccess: async (newPageData) => {
                setPage(newPageData?.current_page);
            },
        }
    );

    const handleChangePage = async (event, newPage) => {
        const page = newPage + 1;
        changePage.mutate({ page });
    };

    const handleChangeRowsPerPage = async (event) => {
        const size = event.target.value;
        setPage(1);
        setRowsPerPage(size);
    };

    return (
        <Paper elevation={0}>
            <TransactionHeader
                returnButton={returnButton}
                setReturnButton={setReturnButton}
            />
            <TableContainer>
                <Table
                    sx={{ minWidth: 500 }}
                    size="small"
                    aria-label="custom pagination table"
                >
                    <TableHead>
                        <TableRow>
                            {columns?.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column?.align}
                                    style={{ minWidth: column?.minWidth }}
                                >
                                    {column?.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {response?.data?.map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                    {columns?.map((column) => {
                                        return (
                                            <TableData
                                                key={column.id}
                                                row={row}
                                                column={column}
                                                index={index}
                                                returnButton={returnButton}
                                                setReturnItem={setReturnItem}
                                                setOpenReturn={setOpenReturn}
                                            />
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={response?.total || 0}
                rowsPerPage={response?.per_page || rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            <ReturnModal
                open={openReturn}
                setOpenReturn={setOpenReturn}
                row={returnItem}
            />
        </Paper>
    );
};

export default TransactionHistory;
