import axios from "../../axios";

export const getTopProduct = async (cookies) => {
    try {
        const { data } = await axios.get("/v1/dashboard/top-product", {
            headers: {
                Authorization: `Bearer ${cookies.user.token}`,
            },
        });
        return data?.response;
    } catch (error) {
        throw error;
    }
};
