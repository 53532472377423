import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getEmployeeUpdatePermission } from "../../../api/employee";

const PermissionModal = ({ cookies, openPermission, handleClose, emp }) => {
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);

    const [id, setId] = useState(null);
    const [inventory, setInventory] = useState(false);
    const [employee, setEmployee] = useState(false);
    const [sales, setSales] = useState(false);
    const [settings, setSettings] = useState(false);

    const resetData = () => {
        setId(null);
        setInventory(false);
        setEmployee(false);
        setSales(false);
        setSettings(false);
    };

    const savePermission = useMutation(
        () =>
            toast.promise(
                getEmployeeUpdatePermission(
                    cookies,
                    id,
                    employee,
                    inventory,
                    sales,
                    settings
                ),
                {
                    loading: "Update permission processing ...",
                    success: <b>Item updated successfully!</b>,
                    error: ({ response }) => `${response.data.message}`,
                }
            ),
        {
            onSuccess: async () => {
                resetData();
                queryClient.invalidateQueries(["get-employees"]);
            },
        }
    );

    const close = () => {
        resetData();
        handleClose();
    };

    const save = () => {
        handleClose();
        savePermission.mutate();
    };

    const handleChange = (permission) => {
        if (permission === "inventory") {
            setInventory(!inventory);
        } else if (permission === "employee") {
            setEmployee(!employee);
        } else if (permission === "sales") {
            setSales(!sales);
        } else if (permission === "settings") {
            setSettings(!settings);
        }
    };

    useEffect(() => {
        setId(emp?.id);
        setInventory(
            emp?.user?.permissions?.some(
                (e) => e.name === "manage inventory"
            ) || false
        );
        setEmployee(
            emp?.user?.permissions?.some(
                (e) => e.name === "manage employees"
            ) || false
        );
        setSales(
            emp?.user?.permissions?.some((e) => e.name === "manage sales") ||
                false
        );
        setSettings(
            emp?.user?.permissions?.some((e) => e.name === "manage settings") ||
                false
        );
    }, [emp]);

    return (
        <div>
            <Dialog open={openPermission} onClose={handleClose}>
                <DialogTitle>
                    {emp?.lastname?.charAt(0)?.toUpperCase() +
                        emp?.lastname?.slice(1)}{" "}
                    Permissions
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={inventory}
                                    color="success"
                                    variant="outlined"
                                    onChange={() => handleChange("inventory")}
                                    name="inventory"
                                />
                            }
                            label="Manage inventory"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employee}
                                    color="success"
                                    variant="outlined"
                                    onChange={() => handleChange("employee")}
                                    name="employee"
                                />
                            }
                            label="Manage employees"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sales}
                                    color="success"
                                    variant="outlined"
                                    onChange={() => handleChange("sales")}
                                    name="sales"
                                />
                            }
                            label="Manage sales"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={settings}
                                    color="success"
                                    variant="outlined"
                                    onChange={() => handleChange("settings")}
                                    name="settings"
                                />
                            }
                            label="Manage settings"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => close()}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => save()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PermissionModal;
