import { Upload } from "upload-js";

const upload = Upload({
    apiKey: "public_FW25baP66pUANMopTTnnHSVgB3Nc",
});
export const getFileSelected = async (file) => {
    try {
        const { fileUrl, filePath } = await upload.uploadFile(file, {
            onBegin: ({ cancel }) => {},
            onProgress: ({ bytesSent, bytesTotal }) => {},
            metadata: {
                productId: 60891,
            },
            tags: ["example_tag"],
            path: {
                folderPath: "/uploads/{UTC_YEAR}/{UTC_MONTH}/{UTC_DAY}",
                fileName: "{UNIQUE_DIGITS_8}{ORIGINAL_FILE_EXT}",
            },
        });
        return fileUrl;
    } catch (e) {
        console.log(`Upload failed: ${e.message}`);
    }
};
