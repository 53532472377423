import TableCell from "@mui/material/TableCell";
import * as React from "react";
import ImageViewer from "awesome-image-viewer";
import IconButton from "@mui/material/IconButton";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

const Data = ({
    handleOpenEdit,
    column,
    row,
    handleDeleteModal,
    handleOpenPermission,
}) => {
    // const value = column?.id === "user.name" ? row.user["name"] : row[column?.id];
    let value;
    if (column?.id === "user.name") {
        value = row?.user["name"];
    } else if (column?.id === "roles") {
        value = row?.user?.permissions;
    } else {
        value = row[column?.id];
    }

    const viewImage = (img, desc) => {
        return new ImageViewer({
            images: [
                {
                    mainUrl: img || "/assets/items/default-image.png",
                    thumbnailUrl: img || "/assets/items/default-image.png",
                    description: desc,
                },
            ],
        });
    };

    return (
        <TableCell key={column?.id}>
            {column.id === "profile_image_link" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        alt="Employee"
                        src={value || "/assets/items/default-image.png"}
                        height={50}
                        style={{ borderRadius: "20%", cursor: "pointer" }}
                        onClick={() => viewImage(value, row["lastname"])}
                    />
                </div>
            ) : column.id === "action" ? (
                <>
                    <IconButton
                        aria-label="update"
                        color="primary"
                        size="small"
                        onClick={() => handleOpenEdit(row)}
                    >
                        <ModeEditRoundedIcon />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteModal(row["id"])}
                    >
                        <DeleteForeverRoundedIcon />
                    </IconButton>
                </>
            ) : column.id === "roles" ? (
                <Stack direction="row" spacing={1}>
                    {value.map((e) => {
                        return (
                            <Chip key={e.id} color="primary" label={e.name} />
                        );
                    })}
                </Stack>
            ) : column.id === "add" ? (
                <IconButton
                    aria-label="add"
                    color="primary"
                    size="small"
                    onClick={() => handleOpenPermission(row)}
                >
                    <SettingsRoundedIcon />
                </IconButton>
            ) : (
                value || "N/A"
            )}
        </TableCell>
    );
};

export default Data;
