import axios from "../axios";

export const getPayment = async (cart, cookies) => {
  try {
    const { data } = await axios.post(
      "/v1/transaction/payment",
      {
        cart,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.user.token}`,
        },
      }
    );
    return data?.items;
  } catch (error) {
    throw error;
  }
};
