import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ImageUploader from "../../ImageUploader";
import toast from "react-hot-toast";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getFileSelected } from "../../../api/fileSelected";
import { getEmployeeEdit } from "../../../api/employee";
import dayjs from "dayjs";

const Edit = ({ employee, cookies, open, handleClose }) => {
  const queryClient = useQueryClient();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [birthday, setBirthday] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    setId(employee?.user?.id);
    setName(employee?.user?.name);
    setFirstname(employee?.firstname);
    setLastname(employee?.lastname);
    setEmail(employee?.user?.email);
    setAge(employee?.age);
    setGender(employee?.gender);
    setAddress(employee?.address);
    setContactNumber(employee?.contact_number);
    setBirthday(employee?.birthday ? dayjs(employee?.birthday) : null);
    setImage(employee?.profile_image_link);
    setSelectedImage(employee?.profile_image_link);
  }, [employee]);

  const resetEditInput = () => {
    setId("");
    setName("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setAge("");
    setGender("");
    setAddress("");
    setContactNumber("");
    setBirthday("");
    setSelectedImage(null);
    setImage(null);
  };

  const close = () => {
    handleClose();
    // resetEditInput();
  };

  const editEmployee = useMutation(
    (link) =>
      toast.promise(
        getEmployeeEdit(
          id,
          link,
          cookies,
          name,
          firstname,
          lastname,
          email,
          age,
          gender,
          address,
          contactNumber,
          birthday
        ),
        {
          loading: "Update employee processing ...",
          success: <b>Item updated successfully!</b>,
          error: ({ response }) => `${response.data.message}`,
        }
      ),
    {
      onSuccess: async () => {
        resetEditInput();
        queryClient.invalidateQueries(["get-employees"]);
      },
    }
  );

  const uploadPhoto = useMutation(() => getFileSelected(image), {
    onSuccess: async (link) => {
      editEmployee.mutate(link);
      setLoadingEdit(false);
      handleClose();
    },
    onError: async () => {
      setLoadingEdit(false);
    },
  });

  const handleEditEmployee = () => {
    setLoadingEdit(true);
    if (selectedImage) {
      uploadPhoto.mutate();
    } else {
      const link = null;
      editEmployee.mutate(link);
      setLoadingEdit(false);
      handleClose();
    }
  };

  const restrictEdit = () => {
    return (
      firstname === "" ||
      lastname === "" ||
      age === "" ||
      gender === "" ||
      address === "" ||
      email === "" ||
      contactNumber === ""
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit employee</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fillup details below.</DialogContentText>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="firstname"
              label="First name *"
              type="text"
              variant="outlined"
              value={firstname}
              disabled={loadingEdit}
              onChange={(e) => setFirstname(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              id="lastname"
              label="Last name *"
              type="text"
              variant="outlined"
              value={lastname}
              disabled={loadingEdit}
              onChange={(e) => setLastname(e.target.value)}
              fullWidth
            />
          </Box>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="age"
              label="Age *"
              type="number"
              variant="outlined"
              value={age}
              disabled={loadingEdit}
              onChange={(e) => setAge(e.target.value)}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="Gender">Gender</InputLabel>
              <Select
                labelId="Gender"
                id="gender"
                value={gender}
                label="Gender *"
                disabled={loadingEdit}
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"rather not say"}>Rather not say</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="address"
              label="Address *"
              type="text"
              variant="outlined"
              value={address}
              disabled={loadingEdit}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              id="contactNumber"
              label="Contact number *"
              type="text"
              variant="outlined"
              value={contactNumber}
              disabled={loadingEdit}
              onChange={(e) => setContactNumber(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ display: "flex", margin: "10px 0" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Birthday"
                inputFormat="YYYY-MM-DD"
                value={birthday}
                onChange={(e) => setBirthday(e)}
                disabled={loadingEdit}
                textField={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              autoFocus
              id="name"
              label="Nickname"
              type="text"
              variant="outlined"
              value={name}
              disabled={loadingEdit}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              autoFocus
              id="email"
              label="Email *"
              type="text"
              variant="outlined"
              value={email}
              disabled={loadingEdit}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              label="Password *"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              disabled={loadingEdit}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <ImageUploader
              disabled={loadingEdit}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setImage={setImage}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingEdit} onClick={() => close()}>
            Cancel
          </Button>
          {loadingEdit ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Update
            </LoadingButton>
          ) : (
            <Button
              disabled={restrictEdit()}
              onClick={() => handleEditEmployee()}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Edit;
