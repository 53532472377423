import {
    Box,
    Button,
    Checkbox,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useState } from "react";
import { useReturnedTableHeader } from "../../../hooks/react-query/TableHeaders";
import { useMutation } from "@tanstack/react-query";
import { getItemReturn } from "../../../api/transactionHistory";
import { useCookies } from "react-cookie";
import ReturnData from "./ReturnData";
import toast from "react-hot-toast";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
};

const ReturnModal = ({ open, setOpenReturn, row }) => {
    const [cookies] = useCookies(["user"]);
    const [selectedRows, setSelectedRows] = useState([]); // Store the selected rows in state
    const { data: columns } = useReturnedTableHeader(
        selectedRows,
        setSelectedRows
    );
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpenReturn(false);
        setSelectedRows([]);
    };

    const handleSelectionModelChange = (newSelection) => {
        const ifExistsIndex = selectedRows.findIndex(
            (e) => e.id === newSelection.id
        );

        let temp = [...selectedRows];

        if (ifExistsIndex !== -1) {
            temp.splice(ifExistsIndex, 1);
        } else {
            temp.push({ ...newSelection, quantity: 1 });
        }
        setSelectedRows(temp);
    };

    const returnItem = useMutation((inventory) =>
        toast.promise(
            getItemReturn(cookies, inventory.id, row.id, inventory.quantity),
            {
                loading: `Returning ${inventory.name}...`,
                success: <b>{inventory.name} returned successfully!</b>,
                error: (err) =>
                    `Error returning ${
                        inventory.name
                    }. ${err.response.data.message.toString()}`,
            }
        )
    );

    const handleChecked = () => {
        handleClose();
        console.log(selectedRows);
        for (const e of selectedRows) {
            console.log(e);
            returnItem.mutate(e);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingBottom: "10px",
                    }}
                >
                    <Button onClick={() => handleChecked()} variant="contained">
                        CONFIRM
                    </Button>
                    <Button
                        onClick={() => handleClose()}
                        sx={{ marginLeft: "5px" }}
                        variant="contained"
                        color="error"
                    >
                        CANCEL
                    </Button>
                </Box>
                <div style={{ height: 400, width: "100%" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {columns?.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align={column?.align}
                                            style={{
                                                minWidth: column?.minWidth,
                                            }}
                                        >
                                            {column?.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.products?.map((row, index) => {
                                    const newRow = {
                                        id: row.inventory.id,
                                        name: row.inventory.name,
                                        net_weight: row.inventory.net_weight,
                                        variant: row.inventory.variant,
                                        price: row.price,
                                        quantity: selectedRows.some(
                                            (e) => row.inventory.id === e.id
                                        )
                                            ? 1
                                            : null,
                                    };
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            {columns?.map((column) => {
                                                return (
                                                    <ReturnData
                                                        key={column.id}
                                                        row={newRow}
                                                        column={column}
                                                        setSelected={
                                                            setSelectedRows
                                                        }
                                                        selectedRows={
                                                            selectedRows
                                                        }
                                                        handleSelectionModelChange={
                                                            handleSelectionModelChange
                                                        }
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Modal>
    );
};

export default ReturnModal;
