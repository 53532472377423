import Dashboard from "../pages/Dashboard";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import Transaction from "../pages/Transaction";
import Inventory from "../pages/Inventory";
import Employee from "../pages/Employee";
import Reports from "../pages/Reports";
import { primaryColor } from "../hooks/theme/primaryColor";
import { useLocation } from "react-router-dom";

function GetPath() {
    const location = useLocation();
    return location.pathname;
}

const report_sub_routes = [
    "/reports/items-sold",
    "/reports/top-products",
    "/reports/transaction-history",
    "/reports/sales",
    "/reports/employees",
    "/reports/logs",
];

const routes = [
    {
        name: "Dashboard",
        path: "/dashboard",
        icon: () => (
            <DashboardRoundedIcon
                style={{
                    color: GetPath() === "/dashboard" ? "#fff" : primaryColor,
                }}
            />
        ),
        component: () => <Dashboard />,
    },
    {
        name: "Transaction",
        path: "/transaction",
        icon: () => (
            <PointOfSaleRoundedIcon
                style={{
                    color: GetPath() === "/transaction" ? "#fff" : primaryColor,
                }}
            />
        ),
        component: () => <Transaction />,
    },
    {
        name: "Inventory",
        path: "/inventory",
        icon: () => (
            <InventoryRoundedIcon
                style={{
                    color: GetPath() === "/inventory" ? "#fff" : primaryColor,
                }}
            />
        ),
        component: () => <Inventory />,
    },
    {
        name: "Manage employee",
        path: "/employee",
        icon: () => (
            <GroupRoundedIcon
                style={{
                    color: GetPath() === "/employee" ? "#fff" : primaryColor,
                }}
            />
        ),
        component: () => <Employee />,
    },
    {
        name: "Reports",
        path: "/reports/items-sold",
        icon: () => (
            <AssessmentRoundedIcon
                style={{
                    color: report_sub_routes.includes(GetPath())
                        ? "#fff"
                        : primaryColor,
                }}
            />
        ),
        component: () => <Reports page={"items-sold"} />,
    },
];

export default routes;
