import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useItemHeader } from "../../hooks/react-query/TableHeaders";
import TablePaginationActions from "../TablePaginationActions";
import { useMutation } from "@tanstack/react-query";
import SearchComponent from "../SearchComponent";
import Box from "@mui/material/Box";
import TableData from "./TableData";
import { useItemRequest } from "../../hooks/react-query/Inventory";
import { getItems } from "../../api/inventory";

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const Items = ({ setCart, cart, cookies }) => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: columns } = useItemHeader();
    const [notLoading, setNotLoading] = useState(false);
    const { data: items } = useItemRequest(cookies, search, page, rowsPerPage);

    const changePage = useMutation(
        ({ page }) => getItems(cookies, search, page, rowsPerPage),
        {
            onSuccess: async (newPageData) => {
                setNotLoading(() => true);
                setPage(newPageData?.current_page || 0);
            },
        }
    );

    const HandleChangePage = async (event, newPage) => {
        const page = newPage + 1;
        changePage.mutate({ page });
        // setNotLoading(() => true)
        // if (notLoading) {
        //   setNotLoading(() => false);
        //   const page = newPage + 1;
        //   changePage.mutate({ page });
        // }
    };

    const handleChangeRowsPerPage = async (event) => {
        const size = event.target.value;
        setPage(1);
        setRowsPerPage(size);
    };

    return (
        <Paper elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
                <SearchComponent
                    search={search}
                    setSearch={setSearch}
                    setPage={setPage}
                />
            </Box>
            <TableContainer sx={{ minHeight: "600px" }}>
                <Table size="small" aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items?.data?.map((row) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                    {columns?.map((column) => {
                                        return (
                                            <TableData
                                                key={column.id}
                                                row={row}
                                                column={column}
                                                setCart={setCart}
                                                cart={cart}
                                            />
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={items?.total || 0}
                rowsPerPage={items?.per_page || rowsPerPage}
                page={page - 1}
                onPageChange={HandleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    );
};

export default Items;
