import { Box } from "@mui/material";
import { useCookies } from "react-cookie";
import TableData from "../components/Employee/TableData";

const Employee = () => {
  const [cookies] = useCookies(["user"]);
  return (
    <Box>
      <TableData cookies={cookies} />
    </Box>
  );
};

export default Employee;
