import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TableHead } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useItemHeader } from "../../hooks/react-query/TableHeaders";
import TablePaginationActions from "../TablePaginationActions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SearchComponent from "../SearchComponent";
import Box from "@mui/material/Box";
import TableData from "./TableData";
import { useItemRequest } from "../../hooks/react-query/Inventory";
import { getDeleteInventory, getItems } from "../../api/inventory";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Add from "./Add";
import { ConfirmationModal } from "../ConfirmationModal";
import toast from "react-hot-toast";
import Edit from "./Edit";

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const Items = ({ cookies }) => {
    const queryClient = useQueryClient();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: columns } = useItemHeader();
    const { data: items } = useItemRequest(cookies, search, page, rowsPerPage);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const [editItem, setEditItem] = useState([]);

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleOpenEdit = (item) => {
        setEditItem(item);
        setOpenEdit(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const handleCloseEdit = () => {
        setEditItem([]);
        setOpenEdit(false);
    };

    const deleteData = useMutation(
        () =>
            toast.promise(getDeleteInventory(cookies, deleteId), {
                loading: "Deleting item ...",
                success: <b>Delete completed!</b>,
                error: ({ response }) => `${response.data.message}`,
            }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["get-items"]);
            },
        }
    );

    const handleDelete = () => {
        setOpenDeleteConfirmation(false);
        deleteData.mutate();
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteConfirmation(true);
        setDeleteId(id);
    };

    const changePage = useMutation(
        ({ page }) => getItems(cookies, search, page, rowsPerPage),
        {
            onSuccess: async (newPageData) => {
                setPage(newPageData?.current_page || 0);
            },
        }
    );

    const HandleChangePage = async (event, newPage) => {
        const page = newPage + 1;
        changePage.mutate({ page });
    };

    const handleChangeRowsPerPage = async (event) => {
        const size = event.target.value;
        setPage(1);
        setRowsPerPage(size);
    };

    return (
        <Paper elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
                <Button
                    sx={{ mx: 1 }}
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    onClick={() => handleOpenAdd()}
                >
                    Add item
                </Button>
                <SearchComponent
                    search={search}
                    setSearch={setSearch}
                    setPage={setPage}
                />
            </Box>
            <TableContainer sx={{ minHeight: "600px" }}>
                <Table size="small" aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items?.data?.map((row) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                    {columns?.map((column) => {
                                        return (
                                            <TableData
                                                handleOpenEdit={handleOpenEdit}
                                                key={column.id}
                                                row={row}
                                                column={column}
                                                handleDeleteModal={
                                                    handleDeleteModal
                                                }
                                            />
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={items?.total || 0}
                rowsPerPage={items?.per_page || rowsPerPage}
                page={page - 1}
                onPageChange={HandleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            <Add
                cookies={cookies}
                open={openAdd}
                handleClose={handleCloseAdd}
            />
            <Edit
                item={editItem}
                cookies={cookies}
                open={openEdit}
                handleClose={handleCloseEdit}
            />
            <ConfirmationModal
                title={"Delete item"}
                message={"Please confirm if you want to delete this item."}
                open={openDeleteConfirmation}
                setOpen={setOpenDeleteConfirmation}
                handleAction={handleDelete}
            />
        </Paper>
    );
};

export default Items;
