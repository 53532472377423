import dayjs from "dayjs";
import axios from "../axios";

export const getSalesReport = async (cookies, payload) => {
    try {
        const { data } = await axios.post(
            "/v1/reports/sales-report",
            {
                sortBy: payload.sortBy,
                year: dayjs(payload.year).year(),
                month: dayjs(payload.month).month(),
                day: dayjs(payload.day).date(),
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.response;
    } catch (error) {
        throw error;
    }
};
