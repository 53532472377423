import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import * as React from "react";
import { Cookies, useCookies } from "react-cookie";
import { useMutation } from "@tanstack/react-query";
import { login, logout } from "../../api/auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Logout = ({ open }) => {
  const [cookies] = useCookies(["user"]);
  const userCookie = new Cookies();
  const navigate = useNavigate();

  const logoutNow = useMutation(() => logout(cookies, userCookie), {
    onSuccess: async (data) => {
      toast.dismiss();
      if (data?.message) {
        toast.success("Successfully logged out");
        navigate("/login", { replace: true });
      } else {
        toast.error(data || "Something went wrong!");
      }
    },
    onError: (e) => {
      toast.dismiss();
      toast.error(e);
    },
  });

  return (
    <List>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={() => logoutNow.mutate()}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <ExitToAppRoundedIcon sx={{ color: "#D32F2FFF" }} />
          </ListItemIcon>
          <ListItemText
            primary={"Logout"}
            sx={{ opacity: open ? 1 : 0, color: "#D32F2FFF" }}
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default Logout;
