import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TableFooter, TableHead, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useCartHeader } from "../../../hooks/react-query/TableHeaders";
import TablePaginationActions from "../../TablePaginationActions";
import Box from "@mui/material/Box";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import IconButton from "@mui/material/IconButton";
import { ConfirmationModal } from "../../ConfirmationModal";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getPayment } from "../../../api/transaction";
import toast from "react-hot-toast";

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const Cart = ({ setCart, cart, cookies }) => {
    const queryClient = useQueryClient();
    const { data: columns } = useCartHeader();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [total, setTotal] = useState(0);

    const payment = useMutation(
        () =>
            toast.promise(getPayment(cart, cookies), {
                loading: "Transaction processing ...",
                success: <b>Transaction completed!</b>,
                error: <b>Something went wrong.</b>,
            }),
        {
            onSuccess: async () => {
                setCart([]);
                queryClient.invalidateQueries(["get-items"]);
            },
        }
    );
    const handlePayment = () => {
        setOpenConfirmation(false);
        payment.mutate();
    };

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    };

    const handleDelete = (id) => {
        const newArr = cart.filter((e) => {
            if (e.id !== id) {
                return e;
            }
        });
        setCart(newArr);
    };

    useEffect(() => {
        let temp_total = 0;
        for (let i = 0; i < cart.length; i++) {
            const obj = cart[i];
            if (obj.hasOwnProperty("price")) {
                temp_total += obj["price"];
            }
        }
        setTotal(temp_total);
    }, [cart]);

    return (
        <Box>
            <Paper elevation={0}>
                <Box
                    sx={{ display: "flex", justifyContent: "flex-start", p: 1 }}
                >
                    <Typography variant={"h6"} component={"h6"}>
                        ADD TO CART
                    </Typography>
                </Box>
                <TableContainer sx={{ minHeight: "500px" }}>
                    <Table size="small" aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                {columns?.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cart?.map((row, index) => (
                                <TableRow hover tabIndex={-1} key={index}>
                                    {columns?.map((column, index) => {
                                        const value = row[column?.id];
                                        return (
                                            <TableCell key={index}>
                                                {column?.id === "action" ? (
                                                    <IconButton
                                                        aria-label="cart"
                                                        color="error"
                                                        size="small"
                                                        onClick={() =>
                                                            handleDelete(row.id)
                                                        }
                                                    >
                                                        <RemoveCircleRoundedIcon />
                                                    </IconButton>
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                            {cart.length > 0 && (
                                <TableRow>
                                    <TableCell colSpan={2} align={"right"}>
                                        <strong>Total</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>{total}</strong>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Button
                variant="contained"
                sx={{ mt: 1 }}
                onClick={() => handleOpenConfirmation()}
                fullWidth
            >
                CONFIRM PAYMENT
            </Button>
            <Button variant="contained" sx={{ mt: 1 }} color="error" fullWidth>
                RESET
            </Button>
            <ConfirmationModal
                title={"Payment Confirmation"}
                message={
                    "Please confirm if you want to purchase these products."
                }
                open={openConfirmation}
                setOpen={setOpenConfirmation}
                handleAction={handlePayment}
            />
        </Box>
    );
};

export default Cart;
