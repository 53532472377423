import { Box, Grid, Paper } from "@mui/material";
import History from "../components/Transaction/history";
import ItemsSold from "../components/ItemsSold";
import Time from "../components/Time";
import Announcements from "../components/Announcements";
import TopProducts from "../components/Dashboard/TopProducts";

const Dashboard = () => {
    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TopProducts />
                </Grid>
                {/* <Grid item xs={4}>
                    <Announcements />
                </Grid> */}
                {/* <Grid item xs={4}>
                    <Time />
                </Grid> */}
                <Grid item xs={12}>
                    {/* <ItemsSold /> */}
                </Grid>
                <Grid item xs={12}>
                    <History />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
