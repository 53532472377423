import axios from "../axios";
import dayjs from "dayjs";

export const getEmployees = async (cookies, search, page, rowsPerPage) => {
    try {
        const { data } = await axios.post(
            `/v1/employee?page=${page}`,
            {
                size: rowsPerPage,
                search: search,
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.employees;
    } catch (error) {
        throw error;
    }
};

export const getEmployeeAdd = async (
    link,
    cookies,
    name,
    email,
    password,
    lastname,
    firstname,
    age,
    gender,
    address,
    contactNumber,
    birthday
) => {
    try {
        const newBday = birthday ? dayjs(birthday).format("YYYY-MM-DD") : null;
        const { data } = await axios.post(
            "/v1/employee/add",
            {
                profile_image_link: link,
                name: name,
                email: email,
                password: password,
                lastname: lastname,
                firstname: firstname,
                age: age,
                gender: gender,
                address: address,
                contact_number: contactNumber,
                birthday: newBday,
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.employee;
    } catch (error) {
        throw error;
    }
};

export const getEmployeeEdit = async (
    id,
    link,
    cookies,
    name,
    firstname,
    lastname,
    email,
    age,
    gender,
    address,
    contactNumber,
    birthday
) => {
    try {
        const newBday = birthday ? dayjs(birthday).format("YYYY-MM-DD") : null;
        const { data } = await axios.post(
            "/v1/employee/update",
            {
                user_id: id,
                name: name,
                lastname: lastname,
                firstname: firstname,
                email: email,
                age: age,
                gender: gender,
                address: address,
                contact_number: contactNumber,
                birthday: newBday,
                profile_image_link: link,
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.employee;
    } catch (error) {
        throw error;
    }
};

export const getDeleteEmployee = async (cookies, id) => {
    try {
        const { data } = await axios.post(
            "/v1/employee/delete",
            { id },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const getEmployeeUpdatePermission = async (
    cookies,
    id,
    manage_employees,
    manage_inventory,
    manage_sales,
    manage_settings
) => {
    try {
        const { data } = await axios.post(
            "/v1/employee/update-permission",
            {
                id,
                manage_employees,
                manage_inventory,
                manage_sales,
                manage_settings,
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.employee;
    } catch (error) {
        throw error;
    }
};

export const getEmployeesReport = async (
    cookies,
    page,
    rowsPerPage,
    sortBy,
    search
) => {
    try {
        const { data } = await axios.post(
            `/v1/reports/employee-report?page=${page}`,
            {
                size: rowsPerPage,
                sortBy,
                search,
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.response;
    } catch (error) {
        throw error;
    }
};
