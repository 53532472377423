import "./App.css";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import NotAuth from "./hooks/auth/NotAuth";
import PrivateRoutes from "./routes/privateRoutes";
import RequireAuth from "./hooks/auth/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";

function App() {
    const queryClient = new QueryClient();

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Toaster />
                {/* <ReactQueryDevtools initialIsOpen /> */}
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route element={<NotAuth />}>
                            <Route path="/login" element={<Login />} />
                        </Route>
                        <Route element={<RequireAuth />}>
                            <Route
                                path="/"
                                element={<Navigate to="/dashboard" />}
                            />
                            <Route path="/*" element={<PrivateRoutes />} />
                        </Route>
                    </Routes>
                </Suspense>
            </QueryClientProvider>
        </div>
    );
}

export default App;
