import { Box, Grid } from "@mui/material";
import Items from "../components/Transaction/Items";
import Cart from "../components/Transaction/AddToCart/Cart";
import { useState } from "react";
import { useCookies } from "react-cookie";

const Transaction = () => {
  const [cookies] = useCookies(["user"]);
  const [cart, setCart] = useState([]);
  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Items setCart={setCart} cart={cart} cookies={cookies} />
        </Grid>
        <Grid item xs={4}>
          <Cart setCart={setCart} cart={cart} cookies={cookies} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Transaction;
