import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ImageUploader from "../../ImageUploader";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import toast from "react-hot-toast";
import { getEmployeeAdd } from "../../../api/employee";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getFileSelected } from "../../../api/fileSelected";

const Add = ({ cookies, open, handleClose }) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const resetAddInput = () => {
    setName("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
    setAge("");
    setGender("");
    setAddress("");
    setContactNumber("");
    setBirthday(null);
    setSelectedImage(null);
    setImage(null);
  };

  const close = () => {
    handleClose();
    resetAddInput();
  };

  const addEmployee = useMutation(
    (link) =>
      toast.promise(
        getEmployeeAdd(
          link,
          cookies,
          name,
          email,
          password,
          lastname,
          firstname,
          age,
          gender,
          address,
          contactNumber,
          birthday
        ),
        {
          loading: "Add employee processing ...",
          success: <b>Employee added successfully!</b>,
          error: ({ response }) => `${response.data.message}`,
        }
      ),
    {
      onSuccess: async () => {
        resetAddInput();
        queryClient.invalidateQueries(["get-employees"]);
      },
    }
  );

  const uploadPhoto = useMutation(() => getFileSelected(image), {
    onSuccess: async (link) => {
      addEmployee.mutate(link);
      setLoadingAdd(false);
      handleClose();
    },
    onError: async () => {
      setLoadingAdd(false);
    },
  });

  const handleAddEmployee = () => {
    setLoadingAdd(true);
    if (selectedImage) {
      uploadPhoto.mutate();
    } else {
      const link = null;
      addEmployee.mutate(link);
      setLoadingAdd(false);
      handleClose();
    }
  };

  const restrictAdd = () => {
    return (
      firstname === "" ||
      lastname === "" ||
      age === "" ||
      gender === "" ||
      address === "" ||
      email === "" ||
      password === "" ||
      contactNumber === ""
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add employee</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fillup details below.</DialogContentText>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="firstname"
              label="First name *"
              type="text"
              variant="outlined"
              value={firstname}
              disabled={loadingAdd}
              onChange={(e) => setFirstname(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              id="lastname"
              label="Last name *"
              type="text"
              variant="outlined"
              value={lastname}
              disabled={loadingAdd}
              onChange={(e) => setLastname(e.target.value)}
              fullWidth
            />
          </Box>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="age"
              label="Age *"
              type="number"
              variant="outlined"
              value={age}
              disabled={loadingAdd}
              onChange={(e) => setAge(e.target.value)}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="Gender">Gender</InputLabel>
              <Select
                labelId="Gender"
                id="gender"
                value={gender}
                label="Gender *"
                disabled={loadingAdd}
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"rather not say"}>Rather not say</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="address"
              label="Address *"
              type="text"
              variant="outlined"
              value={address}
              disabled={loadingAdd}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              id="contactNumber"
              label="Contact number *"
              type="text"
              variant="outlined"
              value={contactNumber}
              disabled={loadingAdd}
              onChange={(e) => setContactNumber(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ display: "flex", margin: "10px 0" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Birthday"
                inputFormat="MM/DD/YYYY"
                value={birthday}
                onChange={(e) => setBirthday(e)}
                disabled={loadingAdd}
                textField={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              autoFocus
              id="name"
              label="Nickname"
              type="text"
              variant="outlined"
              value={name}
              disabled={loadingAdd}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              autoFocus
              id="email"
              label="Email *"
              type="text"
              variant="outlined"
              value={email}
              disabled={loadingAdd}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              label="Password *"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              disabled={loadingAdd}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <ImageUploader
              disabled={loadingAdd}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setImage={setImage}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingAdd} onClick={() => close()}>
            Cancel
          </Button>
          {loadingAdd ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Add
            </LoadingButton>
          ) : (
            <Button
              disabled={restrictAdd()}
              onClick={() => handleAddEmployee()}
            >
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Add;
