import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getInventoryEdit } from "../../../api/inventory";
import { MenuItem } from "@mui/material";
import { useInventoryCategories } from "../../../hooks/react-query/Inventory";
import ImageUploader from "../../ImageUploader";
import toast from "react-hot-toast";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { getFileSelected } from "../../../api/fileSelected";

const Edit = ({ item, cookies, open, handleClose }) => {
  const queryClient = useQueryClient();
  const [id, setId] = useState("");
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [netWeight, setNetWeight] = useState("");
  const [variant, setVariant] = useState("");
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [other, setOther] = useState("");
  const { data: categories } = useInventoryCategories(cookies);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);

  const [loadingEdit, setLoadingEdit] = useState(false);

  useEffect(() => {
    setId(item?.id);
    setCategory(item?.inventory_category?.id);
    setName(item?.name);
    setPrice(item?.price);
    setNetWeight(item?.net_weight);
    setVariant(item?.variant);
    setQuantity(item?.quantity_left);
    setDescription(item?.description);
    setOther(item?.other);
    setImage(item?.image_link);
    setSelectedImage(item?.image_link);
  }, [item]);

  const resetEditInput = () => {
    setId("");
    setCategory("");
    setName("");
    setPrice("");
    setNetWeight("");
    setVariant("");
    setQuantity("");
    setDescription("");
    setOther("");
    setSelectedImage(null);
    setImage(null);
  };

  const close = () => {
    handleClose();
    // resetEditInput();
  };

  const editInventory = useMutation(
    (link) =>
      toast.promise(
        getInventoryEdit(
          id,
          link,
          cookies,
          category,
          name,
          price,
          netWeight,
          variant,
          quantity,
          description,
          other
        ),
        {
          loading: "Update item processing ...",
          success: <b>Item updated successfully!</b>,
          error: <b>Something went wrong.</b>,
        }
      ),
    {
      onSuccess: async () => {
        resetEditInput();
        queryClient.invalidateQueries(["get-items"]);
      },
    }
  );

  const uploadPhoto = useMutation(() => getFileSelected(image), {
    onSuccess: async (link) => {
      editInventory.mutate(link);
      setLoadingEdit(false);
      handleClose();
    },
    onError: async () => {
      setLoadingEdit(false);
    },
  });

  const handleEditInventory = () => {
    setLoadingEdit(true);
    if (selectedImage) {
      uploadPhoto.mutate();
    } else {
      const link = null;
      editInventory.mutate(link);
      setLoadingEdit(false);
      handleClose();
    }
  };

  const restrictEdit = () => {
    return (
      category === "" ||
      name === "" ||
      price === "" ||
      quantity === "" ||
      parseInt(quantity) <= 0 ||
      parseInt(price) < 0
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit inventory</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fillup details below.</DialogContentText>
          <Box sx={{ mt: 2 }}>
            <TextField
              id="outlined-select-currency"
              select
              label="Category *"
              fullWidth
              value={category}
              disabled={loadingEdit}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categories?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              style={{ marginRight: 5 }}
              autoFocus
              id="name"
              label="Name *"
              type="text"
              variant="outlined"
              value={name}
              disabled={loadingEdit}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
            <TextField
              style={{ marginLeft: 5 }}
              autoFocus
              id="price"
              label="Price *"
              type="number"
              variant="outlined"
              value={price}
              disabled={loadingEdit}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
            />
          </Box>
          <Box sx={{ display: "flex", margin: "10px 0 0" }}>
            <TextField
              autoFocus
              id="net_weight"
              label="Net Weight"
              type="text"
              variant="outlined"
              value={netWeight}
              disabled={loadingEdit}
              onChange={(e) => setNetWeight(e.target.value)}
              fullWidth
            />
            <TextField
              style={{ margin: "0 10px" }}
              autoFocus
              id="variant"
              label="Variant"
              type="text"
              variant="outlined"
              value={variant}
              disabled={loadingEdit}
              onChange={(e) => setVariant(e.target.value)}
              fullWidth
            />
            <TextField
              autoFocus
              id="quantity"
              label="Quantity *"
              type="number"
              variant="outlined"
              value={quantity}
              disabled={loadingEdit}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ margin: "10px 0" }}>
            <TextField
              autoFocus
              id="description"
              label="Description"
              type="text"
              variant="outlined"
              value={description}
              disabled={loadingEdit}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
          </Box>
          <Box style={{ margin: "0 0 10px" }}>
            <TextField
              autoFocus
              id="other"
              label="Other"
              type="text"
              variant="outlined"
              value={other}
              disabled={loadingEdit}
              onChange={(e) => setOther(e.target.value)}
              fullWidth
            />
          </Box>
          <Box>
            <ImageUploader
              disabled={loadingEdit}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setImage={setImage}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingEdit} onClick={() => close()}>
            Cancel
          </Button>
          {loadingEdit ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Update
            </LoadingButton>
          ) : (
            <Button
              disabled={restrictEdit()}
              onClick={() => handleEditInventory()}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Edit;
