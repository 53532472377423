import IconButton from "@mui/material/IconButton";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import ImageViewer from "awesome-image-viewer";

const TableData = ({ handleOpenEdit, column, row, handleDeleteModal }) => {
    const value = row[column?.id];
    const viewImage = (img, desc) => {
        return new ImageViewer({
            images: [
                {
                    mainUrl: img || "/assets/items/default-image.png",
                    thumbnailUrl: img || "/assets/items/default-image.png",
                    description: desc,
                },
            ],
        });
    };

    return (
        <TableCell key={column.id} align={column.align}>
            {column.id === "action" ? (
                <>
                    <IconButton
                        aria-label="update"
                        color="primary"
                        size="small"
                        onClick={() => handleOpenEdit(row)}
                    >
                        <ModeEditRoundedIcon />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteModal(row["id"])}
                    >
                        <DeleteForeverRoundedIcon />
                    </IconButton>
                </>
            ) : column.id === "quantity" ? (
                row?.quantity
            ) : column.id === "image_link" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src={value || "/assets/items/default-image.png"}
                        height={60}
                        style={{ borderRadius: "20%", cursor: "pointer" }}
                        onClick={() => viewImage(value, row["name"])}
                    />
                </div>
            ) : (
                value || "N/A"
            )}
        </TableCell>
    );
};

export default TableData;
