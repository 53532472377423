import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEmployeeHeader } from "../../../hooks/react-query/TableHeaders";
import { useEmployeeRequest } from "../../../hooks/react-query/Employee";
import { TablePaginationActions } from "../../TablePagination";
import { getEmployees } from "../../../api/employee";
import Data from "./Data";
import Add from "../Add";
import Edit from "../Edit";
import toast from "react-hot-toast";
import { ConfirmationModal } from "../../ConfirmationModal";
import { getDeleteEmployee } from "../../../api/employee";
import HeaderTabOption from "../HeaderTabOption";
import PermissionModal from "../PermissionModal";

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TableData = ({ cookies }) => {
    const queryClient = useQueryClient();
    const [screen, setScreen] = useState(false);
    const { data: columns } = useEmployeeHeader(screen);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: employees } = useEmployeeRequest(
        cookies,
        search,
        page,
        rowsPerPage
    );
    const [openEdit, setOpenEdit] = useState(false);
    const [editEmp, setEditEmp] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const [openPermission, setOpenPermission] = useState(false);
    const [permission, setPermission] = useState([]);

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const handleOpenEdit = (emp) => {
        setEditEmp(emp);
        setOpenEdit(true);
    };

    const handleOpenPermission = (emp) => {
        setOpenPermission(true);
        setPermission(emp);
    };

    const handleClosePermission = () => {
        setOpenPermission(false);
        setPermission(null);
    };

    const handleCloseEdit = () => {
        setEditEmp([]);
        setOpenEdit(false);
    };

    const deleteData = useMutation(
        () =>
            toast.promise(getDeleteEmployee(cookies, deleteId), {
                loading: "Deleting employee ...",
                success: <b>Delete completed!</b>,
                error: ({ response }) => `${response.data.message}`,
            }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["get-employees"]);
            },
        }
    );

    const handleDelete = () => {
        setOpenDeleteConfirmation(false);
        deleteData.mutate();
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteConfirmation(true);
        setDeleteId(id);
    };

    const changePage = useMutation(
        ({ page }) => getEmployees(cookies, search, page, rowsPerPage),
        {
            onSuccess: async (newPageData) => {
                setPage(newPageData?.current_page || 0);
            },
        }
    );

    const HandleChangePage = async (event, newPage) => {
        const page = newPage + 1;
        changePage.mutate({ page });
    };

    const handleChangeRowsPerPage = async (event) => {
        const size = event.target.value;
        setPage(1);
        setRowsPerPage(size);
    };

    const toggleScreen = () => {
        setScreen(!screen);
        queryClient.invalidateQueries(["get-employee-headers", screen]);
    };

    return (
        <Paper elevation={0}>
            <HeaderTabOption
                setPage={setPage}
                search={search}
                setSearch={setSearch}
                handleOpenAdd={handleOpenAdd}
                screen={screen}
                toggleScreen={toggleScreen}
            />
            <TableContainer sx={{ minHeight: "600px" }}>
                <Table size="small" aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees?.data?.map((row) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                    {columns?.map((column) => {
                                        return (
                                            <Data
                                                handleOpenEdit={handleOpenEdit}
                                                key={column.id}
                                                column={column}
                                                row={row}
                                                handleDeleteModal={
                                                    handleDeleteModal
                                                }
                                                handleOpenPermission={
                                                    handleOpenPermission
                                                }
                                            />
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={employees?.total || 0}
                rowsPerPage={employees?.per_page || rowsPerPage}
                page={page - 1}
                onPageChange={HandleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            <Add
                cookies={cookies}
                open={openAdd}
                handleClose={handleCloseAdd}
            />
            <Edit
                employee={editEmp}
                cookies={cookies}
                open={openEdit}
                handleClose={handleCloseEdit}
            />
            <ConfirmationModal
                title={"Delete employee"}
                message={"Please confirm if you want to delete this employee."}
                open={openDeleteConfirmation}
                setOpen={setOpenDeleteConfirmation}
                handleAction={handleDelete}
            />
            <PermissionModal
                cookies={cookies}
                openPermission={openPermission}
                handleClose={handleClosePermission}
                emp={permission}
            />
        </Paper>
    );
};

export default TableData;
