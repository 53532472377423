import { Box } from "@mui/material";
import ItemsSold from "../components/ItemsSold";
import ReportsTabination from "../components/Reports/ReportsTabination";
import TopProducts from "../components/TopProducts";
import TransactionHistory from "../components/TransactionHistory";
import Sales from "../components/Sales";
import EmployeeReport from "../components/EmployeeReport";
import Logs from "../components/Logs";

const Reports = ({ page }) => {
    return (
        <Box>
            <ReportsTabination page={page} />
            {page === "items-sold" && <ItemsSold />}
            {page === "top-products" && <TopProducts />}
            {page === "transaction-history" && <TransactionHistory />}
            {page === "sales" && <Sales />}
            {page === "employees" && <EmployeeReport />}
            {page === "logs" && <Logs />}
        </Box>
    );
};

export default Reports;
