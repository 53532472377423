import { Box, Button, Typography } from "@mui/material";

const TransactionHeader = ({ returnButton, setReturnButton }) => {
    const handleReturn = () => {
        setReturnButton(!returnButton);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
                component={"h5"}
                variant={"h5"}
                sx={{ fontWeight: "bold", padding: "15px" }}
            >
                TRANSACTION HISTORY
            </Typography>
            <Box sx={{ padding: "15px" }}>
                <Button
                    onClick={() => handleReturn()}
                    variant={returnButton ? "outlined" : "contained"}
                >
                    {returnButton ? "Cancel Return" : "Return Product"}
                </Button>
            </Box>
        </Box>
    );
};

export default TransactionHeader;
