import { Box, IconButton, TableCell, Typography } from "@mui/material";
import ImageViewer from "awesome-image-viewer";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

const TableData = ({
    row,
    column,
    index,
    returnButton,
    setReturnItem,
    setOpenReturn,
}) => {
    const value = row[column?.id];

    const viewImage = (img, desc) => {
        return new ImageViewer({
            images: [
                {
                    mainUrl: img || "/assets/items/default-image.png",
                    thumbnailUrl: img || "/assets/items/default-image.png",
                    description: desc,
                },
            ],
        });
    };

    const handleReturn = () => {
        setReturnItem(row);
        setOpenReturn(true);
    };

    return (
        <TableCell key={column.id} align={column.align}>
            {column.format && typeof value === "number" ? (
                column.format(value)
            ) : column.id === "employee_image" ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img
                        key={column?.id}
                        alt="Product"
                        src={value || "/assets/items/default-image.png"}
                        height={50}
                        style={{
                            borderRadius: "20%",
                            cursor: "pointer",
                        }}
                        onClick={() => viewImage(value, row["name"])}
                    />
                </div>
            ) : column.id === "products" ? (
                <Box>
                    {row["products"].length > 0
                        ? row["products"].map((product) => {
                              return (
                                  <Typography
                                      variant={"caption"}
                                      display="block"
                                      gutterBottom
                                  >
                                      {product.quantity +
                                          " pcs " +
                                          product.inventory.name +
                                          " - " +
                                          product.price +
                                          " pesos"}
                                  </Typography>
                              );
                          })
                        : "N/A"}
                </Box>
            ) : column.id === "action" && returnButton ? (
                <IconButton
                    aria-label="return"
                    color="error"
                    onClick={() => handleReturn()}
                >
                    <KeyboardReturnRoundedIcon />
                </IconButton>
            ) : (
                value || "N/A"
            )}
        </TableCell>
    );
};

export default TableData;
