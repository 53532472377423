import { Box, Paper, Typography } from "@mui/material";
import { useTopProduct } from "../../../hooks/react-query/Dashboard/TopProduct";
import { useCookies } from "react-cookie";

const TopProducts = () => {
    const [cookies] = useCookies(["user"]);
    const { data: product } = useTopProduct(cookies);

    return (
        <Paper
            sx={{
                minWidth: 275,
                maxWidth: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                mb: 3,
                p: 3,
            }}
            elevation={0}
        >
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex" }}>
                    <Typography
                        sx={{ fontWeight: 200 }}
                        component={"h3"}
                        variant={"h3"}
                    >
                        {product?.total_sold}
                    </Typography>
                    <Typography sx={{ mt: 0 }}>sold</Typography>
                </Box>
                <Typography
                    sx={{ fontWeight: "bolder", fontSize: 10 }}
                    component={"h1"}
                    variant={"h1"}
                >
                    TOP PRODUCTS
                </Typography>
            </Box>
            <Box sx={{ ml: 2 }}>
                <img
                    alt="top-product"
                    width={"100%"}
                    src={
                        product?.image ||
                        process.env.PUBLIC_URL +
                            "/assets/items/default-image.png"
                    }
                />
            </Box>
        </Paper>
    );
};

export default TopProducts;
