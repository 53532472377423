import { useQuery } from "@tanstack/react-query";
import { getInventoryCategories, getItems } from "../../../api/inventory";

export const useItemRequest = (cookies, search, page, rowsPerPage) => {
  return useQuery(["get-items", search, page, rowsPerPage], () =>
    getItems(cookies, search, page, rowsPerPage)
  );
};

export const useInventoryCategories = (cookies) => {
  return useQuery(["get-inventory-categories"], () =>
    getInventoryCategories(cookies)
  );
};
