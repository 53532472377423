import axios from "../axios";

export const getTopProducts = async (cookies, page, size) => {
    try {
        const { data } = await axios.post(
            `/v1/reports/top-products?page=${page}`,
            {
                size,
            },
            {
                headers: {
                    Authorization: `Bearer ${cookies.user.token}`,
                },
            }
        );
        return data?.response;
    } catch (error) {
        throw error;
    }
};
