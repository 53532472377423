import React from "react";
import { Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

const ImageUploader = ({
  disabled,
  selectedImage,
  setSelectedImage,
  setImage,
}) => {
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setSelectedImage(URL.createObjectURL(file));
  };

  const removeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      {selectedImage && (
        <img style={{ width: "100%" }} src={selectedImage} alt="Selected" />
      )}
      <input
        type="file"
        id="image-input"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      {selectedImage ? (
        <Button
          disabled={disabled}
          onClick={() => removeImage()}
          sx={{ py: "20px" }}
          variant="outlined"
          color="error"
          component="span"
          startIcon={<RemoveRoundedIcon />}
          fullWidth
        >
          Remove image
        </Button>
      ) : (
        <label htmlFor="image-input">
          <Button
            disabled={disabled}
            sx={{ py: "20px" }}
            variant="outlined"
            color="primary"
            component="span"
            endIcon={<AddRoundedIcon />}
            fullWidth
          >
            Upload Image
          </Button>
        </label>
      )}
    </div>
  );
};

export default ImageUploader;
