import { Box, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import NumberAdjuster from "../../components/NumberAdjuster";

export const useItemHeader = () => {
    return useQuery(["get-inventory-headers"], () => {
        return [
            { id: "image_link", label: "", minWidth: 100 },
            { id: "name", label: "Product", minWidth: 100 },
            { id: "net_weight", label: "Net weight", minWidth: 100 },
            { id: "variant", label: "Variant", minWidth: 100 },
            { id: "description", label: "Description", minWidth: 100 },
            { id: "quantity_left", label: "Quantity", minWidth: 100 },
            { id: "price", label: "Price", minWidth: 100 },
            { id: "action", label: "Action", minWidth: 100 },
        ];
    });
};

export const useCartHeader = () => {
    return useQuery(["get-transaction-cart-headers"], () => {
        return [
            { id: "name", label: "Name", minWidth: 100 },
            { id: "quantity", label: "Qty", minWidth: 100 },
            { id: "price", label: "Price", minWidth: 100 },
            { id: "action", label: "Action", minWidth: 100 },
        ];
    });
};

export const useEmployeeHeader = (screen) => {
    return useQuery(["get-employee-headers", screen], () => {
        return screen
            ? [
                  { id: "profile_image_link", label: "", minWidth: 100 },
                  { id: "lastname", label: "Last name", minWidth: 100 },
                  { id: "firstname", label: "First name", minWidth: 100 },
                  { id: "roles", label: "Roles", minWidth: 100 },
                  { id: "add", label: "Action", minWidth: 100 },
              ]
            : [
                  { id: "profile_image_link", label: "", minWidth: 100 },
                  { id: "lastname", label: "Last name", minWidth: 100 },
                  { id: "firstname", label: "First name", minWidth: 100 },
                  { id: "user.name", label: "Nickname", minWidth: 100 },
                  { id: "age", label: "Age", minWidth: 100 },
                  { id: "gender", label: "Gender", minWidth: 100 },
                  { id: "address", label: "Address", minWidth: 100 },
                  {
                      id: "contact_number",
                      label: "Contact number",
                      minWidth: 100,
                  },
                  { id: "birthday", label: "Birthday", minWidth: 100 },
                  { id: "action", label: "Action", minWidth: 100 },
              ];
    });
};

export const useEmployeeReportHeader = () => {
    return useQuery(["get-employee-report-header"], () => {
        return [
            { id: "image", label: "", minWidth: 100 },
            { id: "id", label: "Emp ID", minWidth: 100 },
            { id: "name", label: "Name", minWidth: 100 },
            { id: "total_sales", label: "Total sales", minWidth: 100 },
            { id: "item_returned", label: "Returned", minWidth: 100 },
            { id: "item_sold", label: "Sold", minWidth: 100 },
            { id: "status", label: "Status", minWidth: 100 },
        ];
    });
};

export const useItemsSoldHeader = () => {
    return useQuery(["get-items-sold-headers"], () => {
        return [
            { id: "item_image", label: "", minWidth: 100 },
            { id: "item_id", label: "Item ID", minWidth: 100 },
            { id: "item_name", label: "Item name", minWidth: 100 },
            { id: "item_price", label: "Item price", minWidth: 100 },
            { id: "cashier_id", label: "Cashier ID", minWidth: 100 },
            { id: "cashier_name", label: "Cashier name", minWidth: 100 },
            { id: "customer_id", label: "Customer ID", minWidth: 100 },
            { id: "customer_name", label: "Customer name", minWidth: 100 },
        ];
    });
};

export const useLogsHeader = () => {
    return useQuery(["get-logs-header"], () => {
        return [
            { id: "id", label: "ID", minWidth: 100 },
            { id: "name", label: "Name", minWidth: 100 },
            { id: "log", label: "Log", minWidth: 100, align: "center" },
        ];
    });
};

export const useTopProductHeader = () => {
    return useQuery(["get-top-product-headers"], () => {
        return [
            { id: "no", label: "No", minWidth: 100 },
            { id: "image_link", label: "", minWidth: 100 },
            { id: "id", label: "Product ID", minWidth: 100 },
            { id: "name", label: "Product name", minWidth: 100 },
            { id: "net_weight", label: "Net weight", minWidth: 100 },
            { id: "variant", label: "Variant", minWidth: 100 },
            { id: "description", label: "Description", minWidth: 100 },
            { id: "price", label: "Product price", minWidth: 100 },
            { id: "total_sold", label: "Total sold", minWidth: 100 },
            { id: "total_sales", label: "Total sales", minWidth: 100 },
        ];
    });
};

export const useTransactionHistoryHeader = (returnButton) => {
    return useQuery(["get-transaction-history-headers", returnButton], () => {
        const headers = [
            { id: "id", label: "ID", minWidth: 100 },
            { id: "employee_image", label: "", minWidth: 100 },
            { id: "employee", label: "Employee name", minWidth: 100 },
            { id: "customer", label: "Customer name", minWidth: 100 },
            { id: "products", label: "Products bought", minWidth: 100 },
            { id: "total", label: "Total", minWidth: 100 },
            {
                id: "transaction_date",
                label: "Transaction date",
                minWidth: 100,
            },
        ];

        return returnButton
            ? [...headers, { id: "action", label: "Action", minWidth: 100 }]
            : headers;
    });
};

export const useReturnedTableHeader = (selectedRows, setSelected) => {
    return useQuery(["get-returned-headers"], () => {
        return [
            { id: "checkbox", label: "", minWidth: 70 },
            { id: "id", label: "ID", minWidth: 70 },
            { id: "name", label: "Name", minWidth: 130 },
            { id: "price", label: "Price", minWidth: 130 },
            { id: "net_weight", label: "Net weight", minWidth: 130 },
            { id: "variant", label: "Variant", minWidth: 130 },
            {
                id: "quantity",
                label: "Quantity",
                minWidth: 130,
            },
        ];
    });
};
