import { useQuery } from "@tanstack/react-query";
import { getEmployees, getEmployeesReport } from "../../../api/employee";

export const useEmployeeRequest = (cookies, search, page, rowsPerPage) => {
    return useQuery(["get-employees", search, page, rowsPerPage], () =>
        getEmployees(cookies, search, page, rowsPerPage)
    );
};

export const useEmployeeReportRequest = (
    cookies,
    page,
    rowsPerPage,
    sortBy,
    search
) => {
    return useQuery(
        ["get-employees-report", page, rowsPerPage, sortBy, search],
        () => getEmployeesReport(cookies, page, rowsPerPage, sortBy, search)
    );
};
