import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Add = ({ open, handleClose, handleAdd, quantity, setQuantity }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Input Quantity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide product quantity below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Quantity"
            type="number"
            fullWidth
            variant="standard"
            value={quantity}
            onChange={(e) =>
              setQuantity(
                e.target.value === ""
                  ? e.target.value
                  : parseInt(e.target.value)
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            disabled={quantity === "" || quantity === 0}
            onClick={() => handleAdd(quantity)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Add;
