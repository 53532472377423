import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TablePaginationActions } from "../TablePagination";
import { useState } from "react";
import { useTopProductHeader } from "../../hooks/react-query/TableHeaders";
import { useCookies } from "react-cookie";
import ImageViewer from "awesome-image-viewer";
import { useMutation } from "@tanstack/react-query";
import { useTopProducts } from "../../hooks/react-query/TopProducts";
import { getTopProducts } from "../../api/topProducts";

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TopProducts = () => {
    const [cookies] = useCookies(["user"]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: response } = useTopProducts(cookies, page, rowsPerPage);
    const { data: columns } = useTopProductHeader();

    const changePage = useMutation(
        ({ page }) => getTopProducts(cookies, page, rowsPerPage),
        {
            onSuccess: async (newPageData) => {
                setPage(newPageData?.current_page);
            },
        }
    );

    const handleChangePage = async (event, newPage) => {
        const page = newPage + 1;
        changePage.mutate({ page });
    };

    const handleChangeRowsPerPage = async (event) => {
        const size = event.target.value;
        setPage(1);
        setRowsPerPage(size);
    };

    const viewImage = (img, desc) => {
        return new ImageViewer({
            images: [
                {
                    mainUrl: img || "/assets/items/default-image.png",
                    thumbnailUrl: img || "/assets/items/default-image.png",
                    description: desc,
                },
            ],
        });
    };

    return (
        <Paper elevation={0}>
            <Typography
                component={"h5"}
                variant={"h5"}
                sx={{ fontWeight: "bold", padding: "15px" }}
            >
                TOP PRODUCTS
            </Typography>
            <TableContainer>
                <Table
                    sx={{ minWidth: 500 }}
                    size="small"
                    aria-label="custom pagination table"
                >
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {response?.data?.map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                    {columns?.map((column) => {
                                        const value = row[column?.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.format &&
                                                typeof value === "number" ? (
                                                    column.format(value)
                                                ) : column.id ===
                                                  "image_link" ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <img
                                                            key={column?.id}
                                                            alt="Product"
                                                            src={
                                                                value ||
                                                                "/assets/items/default-image.png"
                                                            }
                                                            height={50}
                                                            style={{
                                                                borderRadius:
                                                                    "20%",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                viewImage(
                                                                    value,
                                                                    row["name"]
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ) : column.id === "no" ? (
                                                    index + 1
                                                ) : (
                                                    value || "N/A"
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={response?.total || 0}
                rowsPerPage={response?.per_page || rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    );
};

export default TopProducts;
