import { Box, IconButton } from "@mui/material";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { useEffect, useState } from "react";

const NumberAdjuster = ({ value, selectedRows, setSelected }, index) => {
    const [currentValue, setCurrentValue] = useState({});

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleDecrease = () => {
        const newValue =
            currentValue?.quantity <= 1 ? 1 : currentValue?.quantity - 1;

        const newSelectedRows = selectedRows.map((e) => {
            if (e.id === currentValue.id) {
                return {
                    ...e,
                    quantity: newValue,
                };
            } else {
                return e;
            }
        });

        setSelected(newSelectedRows);
    };

    const handleIncrease = () => {
        const newValue = currentValue?.quantity + 1;

        const newSelectedRows = selectedRows.map((e) => {
            if (e.id === currentValue.id) {
                return {
                    ...e,
                    quantity: newValue,
                };
            } else {
                return e;
            }
        });

        setSelected(newSelectedRows);
    };

    return (
        value && (
            <Box key={index} sx={{ display: "flex" }}>
                <IconButton
                    onClick={() => handleDecrease()}
                    color="primary"
                    aria-label="left"
                >
                    <ArrowCircleLeftRoundedIcon />
                </IconButton>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {currentValue?.quantity}
                </Box>
                <IconButton
                    onClick={() => handleIncrease()}
                    color="primary"
                    aria-label="right"
                >
                    <ArrowCircleRightRoundedIcon />
                </IconButton>
            </Box>
        )
    );
};

export default NumberAdjuster;
