import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { login } from "../api/auth";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies(["user"]);
  const [error, setError] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginData = useMutation(
    ({ email, password }) => login({ email, password }),
    {
      onSuccess: async (data) => {
        toast.dismiss();
        if (data?.user) {
          toast.success("Successfully logged in");
          setCookie("user", data);
          navigate(from, { replace: true, state: { loginSuccess: true } });
        } else {
          setError(true);
          toast.error(data || "Something went wrong!");
        }
      },
      onError: (e) => {
        setError(true);
        toast.dismiss();
        toast.error(e);
      },
    }
  );
  const loginNow = () => {
    try {
      loginData.mutate({ email, password });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container sx={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <Paper
        elevation={3}
        sx={{
          width: "400px",
          height: "450px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Typography
            sx={{ textAlign: "center" }}
            component={"h4"}
            variant={"h4"}
          >
            WELCOME
          </Typography>
          <TextField
            error={error}
            sx={{ my: "5px" }}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <FormControl sx={{ my: "5px" }} variant="outlined" fullWidth>
            <InputLabel
              htmlFor="outlined-adornment-password"
              error={error}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              sx={error ? { color: "#D32F2F" } : { color: "#666666" }}
            >
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              error={error}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            sx={{ width: "100%", my: "5px" }}
            variant="contained"
            size={"large"}
            onClick={() => loginNow()}
          >
            Login
          </Button>
        </Container>
      </Paper>
    </Container>
  );
};

export default Login;
