import { Checkbox, TableCell } from "@mui/material";
import NumberAdjuster from "../../NumberAdjuster";

const ReturnData = ({
    row,
    column,
    setSelected,
    selectedRows,
    handleSelectionModelChange,
}) => {
    const value = row[column.id];

    return (
        <TableCell key={row.id}>
            {column.id === "checkbox" ? (
                <Checkbox
                    color="primary"
                    checked={selectedRows.some((e) => e.id === row.id)}
                    onChange={() => handleSelectionModelChange(row)}
                    inputProps={{
                        "aria-labelledby": row.id,
                    }}
                />
            ) : column.id === "quantity" ? (
                <NumberAdjuster
                    value={selectedRows.find((e) => e.id === row.id)}
                    selectedRows={selectedRows}
                    setSelected={setSelected}
                />
            ) : (
                value
            )}
        </TableCell>
    );
};

export default ReturnData;
