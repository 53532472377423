import axios from "../axios";

export const getItems = async (cookies, search, page, rowsPerPage) => {
  try {
    const { data } = await axios.post(
      `/v1/transaction/items?page=${page}`,
      {
        size: rowsPerPage,
        search: search,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.user.token}`,
        },
      }
    );
    return data?.items;
  } catch (error) {
    throw error;
  }
};

export const getInventoryAdd = async (
  link,
  cookies,
  category,
  name,
  price,
  netWeight,
  variant,
  quantity,
  description,
  other
) => {
  try {
    const { data } = await axios.post(
      "/v1/inventory/add",
      {
        inventory_category_id: category,
        name: name,
        price: price,
        net_weight: netWeight,
        variant: variant,
        quantity_left: quantity,
        description: description,
        other: other,
        image_link: link,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.user.token}`,
        },
      }
    );
    return data?.items;
  } catch (error) {
    throw error;
  }
};

export const getInventoryEdit = async (
  id,
  link,
  cookies,
  category,
  name,
  price,
  netWeight,
  variant,
  quantity,
  description,
  other
) => {
  try {
    const { data } = await axios.post(
      "/v1/inventory/update",
      {
        id: id,
        inventory_category_id: category,
        name: name,
        price: price,
        net_weight: netWeight,
        variant: variant,
        quantity_left: quantity,
        description: description,
        other: other,
        image_link: link,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.user.token}`,
        },
      }
    );
    return data?.items;
  } catch (error) {
    throw error;
  }
};

export const getInventoryCategories = async (cookies) => {
  try {
    const { data } = await axios.get("/v1/inventory/categories", {
      headers: {
        Authorization: `Bearer ${cookies.user.token}`,
      },
    });
    return data?.categories;
  } catch (error) {
    throw error;
  }
};

export const getDeleteInventory = async (cookies, id) => {
  try {
    const { data } = await axios.post(
      "/v1/inventory/delete",
      { id },
      {
        headers: {
          Authorization: `Bearer ${cookies.user.token}`,
        },
      }
    );
    return data?.categories;
  } catch (error) {
    throw error;
  }
};
