import ShoppingCartCheckoutRoundedIcon from "@mui/icons-material/ShoppingCartCheckoutRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import AccessibilityRoundedIcon from "@mui/icons-material/AccessibilityRounded";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ReportsTabination = ({ page }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", pb: 1 }}>
            <Button
                sx={{ mx: 1 }}
                variant={page === "items-sold" ? "contained" : "outlined"}
                size="small"
                startIcon={<ShoppingCartCheckoutRoundedIcon />}
                onClick={() =>
                    navigate("/reports/items-sold", { replace: true })
                }
            >
                Items sold
            </Button>
            <Button
                sx={{ mx: 1 }}
                variant={page === "top-products" ? "contained" : "outlined"}
                size="small"
                startIcon={<InventoryRoundedIcon />}
                onClick={() =>
                    navigate("/reports/top-products", { replace: true })
                }
            >
                Top products
            </Button>
            <Button
                sx={{ mx: 1 }}
                variant={
                    page === "transaction-history" ? "contained" : "outlined"
                }
                size="small"
                startIcon={<HistoryRoundedIcon />}
                onClick={() =>
                    navigate("/reports/transaction-history", { replace: true })
                }
            >
                Transaction history
            </Button>
            <Button
                sx={{ mx: 1 }}
                variant={page === "sales" ? "contained" : "outlined"}
                size="small"
                startIcon={<AssessmentRoundedIcon />}
                onClick={() => navigate("/reports/sales", { replace: true })}
            >
                Sales reports
            </Button>
            <Button
                sx={{ mx: 1 }}
                variant={page === "employees" ? "contained" : "outlined"}
                size="small"
                startIcon={<AccessibilityRoundedIcon />}
                onClick={() =>
                    navigate("/reports/employees", { replace: true })
                }
            >
                Employee Reports
            </Button>
            <Button
                sx={{ mx: 1 }}
                variant={page === "logs" ? "contained" : "outlined"}
                size="small"
                startIcon={<EditCalendarRoundedIcon />}
                onClick={() => navigate("/reports/logs", { replace: true })}
            >
                Log reports
            </Button>
        </Box>
    );
};

export default ReportsTabination;
