import { Box } from "@mui/material";
import { useCookies } from "react-cookie";
import Items from "../components/Inventory/Items";

const Inventory = () => {
  const [cookies] = useCookies(["user"]);
  return (
    <Box>
      <Items cookies={cookies} />
    </Box>
  );
};

export default Inventory;
