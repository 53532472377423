import IconButton from "@mui/material/IconButton";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { primaryColor } from "../../hooks/theme/primaryColor";
import TableCell from "@mui/material/TableCell";
import * as React from "react";
import ImageViewer from "awesome-image-viewer";
import { useState } from "react";
import Add from "./AddToCart/Add";
import toast from "react-hot-toast";

const TableData = ({ column, row, setCart, cart }) => {
  const [quantity, setQuantity] = useState(1);
  const value = row[column?.id];
  const [open, setOpen] = useState(false); // Open add to cart
  const [selectedItem, setSelectedItem] = useState({});

  const handleClickOpen = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };

  const handleClose = () => {
    setQuantity(1);
    setSelectedItem({});
    setOpen(false);
  };

  const checkItemNoLeft = (quantity) => {
    const no_left = selectedItem.quantity_left < quantity;
    if (no_left) {
      toast.error(`Not enough supply for ${selectedItem.name}.`);
    }
    return no_left;
  };

  const checkItemDuplication = (q) => {
    let exist = false;
    const newArr = cart.map((e) => {
      if (e.id === selectedItem.id) {
        if (!checkItemNoLeft(e.quantity + q)) {
          e.price += selectedItem.price * q;
          e.quantity += q;
        }
        exist = true;
        return e;
      } else {
        return e;
      }
    });
    setCart(newArr);
    return exist;
  };

  const handleAdd = (quantity) => {
    if (!checkItemNoLeft(quantity)) {
      const exist = checkItemDuplication(quantity);
      if (!exist) {
        setCart([
          ...cart,
          {
            id: selectedItem.id,
            name: selectedItem.name,
            price: selectedItem.price * quantity,
            quantity: quantity,
          },
        ]);
      }
    }
    setOpen(false);
    setSelectedItem({});
    setQuantity(1);
  };

  const viewImage = (img, desc) => {
    return new ImageViewer({
      images: [
        {
          mainUrl: img,
          thumbnailUrl: img,
          description: desc,
        },
      ],
    });
  };

  return (
    <TableCell key={column.id} align={column.align}>
      {column.id === "action" ? (
        <IconButton
          aria-label="add to cart"
          color="primary"
          size="small"
          onClick={() => handleClickOpen(row)}
        >
          <ShoppingCartRoundedIcon style={{ color: primaryColor }} />
        </IconButton>
      ) : column.id === "quantity" ? (
        row?.quantity
      ) : column.id === "image_link" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={value || "/assets/items/default-image.png"}
            height={60}
            style={{ borderRadius: "20%", cursor: "pointer" }}
            onClick={() => viewImage(value, row["name"])}
          />
        </div>
      ) : (
        value
      )}
      <Add
        open={open}
        handleClose={handleClose}
        handleAdd={handleAdd}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    </TableCell>
  );
};

export default TableData;
